import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "text-lg ml-2 p-3 text-white bg-red-500 ui-test-disabled-buttons-label"
}

import { useI18n } from 'vue-i18n';
import IExportType from '@/interfaces/invoices/iExportType';


export default /*@__PURE__*/_defineComponent({
  __name: 'export-actions',
  props: {
	loading: {
		type: Boolean,
		default: false
	},
	hasDataErrors: {
		type: Boolean,
		default: false
	},
	exportButtonsDisabled: {
		type: Boolean,
		default: false
	},
	exportViesButtonDisabled: {
		type: Boolean,
		default: false
	}
},
  emits: ['onExportClick', 'onExportViesClick'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();


const emit = __emit;
const handleExportClick = (fileType: 'CSV' | 'TXT') => {
	const exportType: IExportType = {
		fileType,
		includeVies: false
	}
	emit('onExportClick', exportType);
}
const handleExportViesClick = () => {
	emit('onExportViesClick');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        disabled: __props.exportButtonsDisabled,
        class: _normalizeClass(["btn btn-xs ui-test-export-button", {'btn-loading': __props.loading}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleExportClick('CSV')))
      }, _toDisplayString(_unref(localization).t('export-actions.csv')), 11, _hoisted_1),
      (!__props.loading)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            disabled: __props.exportButtonsDisabled,
            class: "btn btn-xs ui-test-export-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (handleExportClick('TXT')))
          }, _toDisplayString(_unref(localization).t('export-actions.txt')), 9, _hoisted_2))
        : _createCommentVNode("", true),
      (!__props.loading)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            disabled: __props.exportButtonsDisabled || __props.exportViesButtonDisabled,
            class: "btn btn-xs ui-test-export-vies-button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (handleExportViesClick()))
          }, _toDisplayString(_unref(localization).t('export-actions.vies')), 9, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    (__props.hasDataErrors)
      ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_unref(localization).t('export-actions.cannot-export')), 1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})