import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "actions button-group flex items-center ml-4" }
const _hoisted_2 = { class: "data-options ml-auto" }
const _hoisted_3 = {
  key: 0,
  class: "btn-group paging flex justify-end items-center ui-test-paging"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 1,
  class: "btn-group flex justify-end items-center input-container ui-test-custom-page"
}
const _hoisted_7 = ["max"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 1,
  class: "no-data ui-test-no-data"
}
const _hoisted_10 = ["rowspan"]

import { computed, ref, toRef, watch, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon, ReplyIcon } from '@heroicons/vue/outline';
import gridHead from './grid-head.vue';
import gridBody from './grid-body.vue';
import IGridConfig from "./interfaces/iGridConfig";
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import IInvoice from '@/interfaces/invoices/iInvoice';
import IListOptions from '@/interfaces/invoices/iListOptions';
import IListFilter from '@/interfaces/invoices/iListFilter';
import IProfile from '@/interfaces/users/iProfile';


export default /*@__PURE__*/_defineComponent({
  __name: 'grid',
  props: {
	config: Object as () => IGridConfig,
	data: Array as () => IInvoice[],
	total: Number,
	listOptions: {
		type: Object as () => IListOptions,
		default: () => { return {} }
	},
	loading: {
		type: Object,
		default: () => {
			return {
				export: false,
				grid: false
			}
		}
	},
	hasDataErrors: {
		type: Boolean,
		default: false
	},
	profileData: Object as () => IProfile,
	isSelectAllMode: {
		type: Boolean,
		default: false
	},
	selections: {
		type: Object as () => { itemIds: string[], excludedItemIds: string[] },
		default: () => {
			return {
				itemIds: [],
				excludedItemIds: []
			}
		}
	},
	rowKeysConfig: {
		type: Array as () => {
			key: string,
			label?: string,
			class?: string,
			width?: number | string,
			minWidth?: number
		}[]
	}
},
  emits: ["onChangePage", "onResetSelection", "onChangeFilters", "onChangeSorting", "onChangeSelection", "onChangeSelectAllMode"],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const emit = __emit;
const props = __props;
		
const selectedItemIds = ref(props.selections?.itemIds);
const selectionExcludingItemIds = ref(props.selections?.excludedItemIds);

const data: Ref<IInvoice[] | undefined> = toRef(props, 'data');
const filtersActive = ref(false);
const firstLoadCompleted = ref(false);
const customPage = ref(props.listOptions.pageIndex + 1);
const wantCustomPage = ref(false);

const firstLoadWatch = watch(() => props.loading.grid, () => {
	if (props.loading.grid === true && firstLoadCompleted.value === false) {
		firstLoadCompleted.value = true;
		firstLoadWatch();
	}
});

watch(()=> props.listOptions.pageIndex, (current, prev)=>{
	if (current === 0 && current < prev) {
		customPage.value = 1;
	} else {
		customPage.value = current + 1;
	}
});

const selectionLabelDisabled = computed(() => {
	
	return props.loading.export
		|| props.loading.grid 
		|| props.total === 0
		|| props.hasDataErrors;
});

const totalPages = computed(() => {
	const totalPagesCount = Math.ceil((props.total || 0) / props.listOptions.pageSize);
	return totalPagesCount > 0 ? totalPagesCount : 1;
});

const gridHeadConfig = computed(() => {
	return (props.rowKeysConfig || [])
		.map((rowConfig) => {
			const headConfig: IGridHeadCellConfig  = {
				key: rowConfig.key,
				label: rowConfig.label || rowConfig.key
			}

			if (rowConfig.width) {
				headConfig.width = rowConfig.width;
			}

			if (rowConfig.minWidth) {
				headConfig.minWidth = rowConfig.minWidth;
			}

			if (rowConfig.class) {
				headConfig.class = rowConfig.class;
			}

			const filterOption = (props.config?.filterFields || []).find(f => f.name.toLowerCase() === rowConfig.key.toLowerCase());
			headConfig.filterOptions = filterOption;

			const sortField = (props.config?.sortFields || []).find(f => f.field.toLowerCase() === rowConfig.key.toLowerCase());
			headConfig.sortField = sortField;
			
			return headConfig;
		});

});

const visibleData = computed(() => {
	const allData = data.value;
	const visibleFields = (props.rowKeysConfig || []).map(rConfig => rConfig.key);
	const filteredData = (allData || []).map(d => {
		const field = {} as IInvoice;
		visibleFields.forEach(f => (field as {[key:string]: any})[f] = (d as {[key:string]: any})[f]);
		return field;
	});

	return filteredData;
});

const selectionLabel = computed(() => {
	let label = localization.t('grid.export');

	if (props.loading.grid) {
		return localization.t('grid.loading-data')
	}

	if (props.loading.export) {
		return localization.t('grid.exporting')
	}

	if (props.total === 0) {
		return firstLoadCompleted.value === true 
			? localization.t('grid.no-data')
			: label;
	}

	if (selectedItemIds.value?.length === 0) {
		label = `${label} ${localization.t('grid.all')} ${(props.total || 0)}`;
	} else {
		label = `${label} ${localization.t('grid.selected')} ${selectedItemIds.value?.length}`;
	}
	label = `${label} ${selectedItemIds.value?.length === 1 ? localization.t('grid.invoice'): localization.t('grid.invoices')}: `

	return label;
});

const noDataMessage = computed(() => {
	return firstLoadCompleted.value === true 
		? localization.t('grid.no-invoices')
		: localization.t('grid.use-filters')
});

const prevButtonDisabled = computed(() => {
	return props.loading.grid || props.loading.export || props.listOptions.pageIndex === 0
});

const nextButtonDisabled = computed(() => {
	const reachedEnd = props.listOptions.pageIndex >= Math.ceil((props.total || 0) / props.listOptions.pageSize) - 1;
	return props.loading.grid || props.loading.export || reachedEnd;
});

const goToButtonDisabled = computed(() => {
	return props.loading.grid || props.loading.export || !customPage.value
});

const resetSelections = () => {
	emit("onResetSelection");
}

const onNextPage = () => {
	const newPageValue = props.listOptions.pageIndex + 1;
	customPage.value = newPageValue + 1;
	onChangePage(newPageValue);
}

const onPrevPage = () => {
	const newPageValue = props.listOptions.pageIndex - 1;
	customPage.value = newPageValue + 1;
	onChangePage(newPageValue);
}

const onCustomPage = () => {
	if (customPage.value) {
		wantCustomPage.value = !wantCustomPage.value;
		onChangePage(customPage.value - 1);
	}
}

const onChangePage = (newPage: number) => {
	resetSelections();
	emit('onChangePage', newPage);
}

const onSwitch = () => {
	wantCustomPage.value = !wantCustomPage.value;
}

const addToSelections = (value: string, selectionsCollection: string[]) => {
	const hasItem = selectionsCollection.indexOf(value) > -1;

	if (!hasItem) {
		selectionsCollection.push(value);
	} else {
		selectionsCollection.splice(selectionsCollection.indexOf(value), 1);
	}
	emit("onChangeSelection", selectionsCollection);
}

const handleChangePageInput = () => {
	if (customPage.value && customPage.value > totalPages.value) {
		customPage.value = totalPages.value;
	} else if (customPage.value && customPage.value < 1 && (-customPage.value) >= 0) {
		customPage.value = 1;
	}
}

const handleSelectionChange = (newSelection: string) => {
	if (!props.isSelectAllMode) {
		addToSelections(newSelection, selectedItemIds.value);
	} else {
		addToSelections(newSelection, selectionExcludingItemIds.value);
	}
}

const handleClearSelectionClick = () => {
	resetSelections();
}

const handleFiltersActive = (isFiltersActive: boolean) => {
	filtersActive.value = isFiltersActive;
}

const handleFiltersChange = (filter: IListFilter[]) => {
	emit('onChangeSelectAllMode', false);
	resetSelections();
	emit('onChangeFilters', filter);
}

const handleSortingChange = (field: string, value: "asc" | "desc") => {
	emit('onChangeSelectAllMode', false);
	resetSelections();
	emit('onChangeSorting', field, value);
}

const handleSelectAllChange = (isSelectAllChecked: boolean) => {
	resetSelections();
	emit('onChangeSelectAllMode', isSelectAllChecked);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["ho-table-controls flex items-center px-3 @apply rounded-t ui-test-table-controls", { 'ho-grid-filters-active': filtersActive.value }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", {
          class: _normalizeClass([{ disabled: selectionLabelDisabled.value }, "mr-2 ui-test-selection-label"])
        }, _toDisplayString(selectionLabel.value), 3),
        _renderSlot(_ctx.$slots, "topBarPlaceholder"),
        (selectedItemIds.value.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-bare btn-light ui-test-clear-button",
              onClick: handleClearSelectionClick
            }, _toDisplayString(_unref(localization).t('grid.clear-selection')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (!wantCustomPage.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "btn btn-xs btn-bare text-peri-400 ui-test-prev-button",
                onClick: onPrevPage,
                disabled: prevButtonDisabled.value
              }, [
                _createVNode(_unref(ChevronLeftIcon), { class: "w-8" })
              ], 8, _hoisted_4),
              _createElementVNode("label", {
                class: _normalizeClass(["label-pages ui-test-switch", { disabled: __props.loading.grid || __props.loading.export }]),
                onClick: onSwitch
              }, _toDisplayString(__props.listOptions.pageIndex + 1) + " / " + _toDisplayString(totalPages.value), 3),
              _createElementVNode("button", {
                class: "btn btn-bare text-peri-400 ui-test-next-button",
                onClick: onNextPage,
                disabled: nextButtonDisabled.value
              }, [
                _createVNode(_unref(ChevronRightIcon), { class: "w-8" })
              ], 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        (wantCustomPage.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_unref(ReplyIcon), {
                class: "switch-paging w-6 mr-4 cursor-pointer text-peri-400 hover:text-peri-500 ui-test-switch",
                onClick: onSwitch
              }),
              _cache[1] || (_cache[1] = _createElementVNode("label", { class: "switch-label" }, "Go to", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                onInput: handleChangePageInput,
                onKeyup: _withKeys(onCustomPage, ["enter"]),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((customPage).value = $event)),
                name: "customPage",
                placeholder: "1",
                min: "1",
                max: totalPages.value
              }, null, 40, _hoisted_7), [
                [_vModelText, customPage.value]
              ]),
              _createElementVNode("button", {
                class: "btn btn-bare ml-1 text-peri-400 ui-test-go-to-button",
                onClick: onCustomPage,
                disabled: goToButtonDisabled.value
              }, [
                _createVNode(_unref(ChevronDoubleRightIcon), { class: "w-8" })
              ], 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createElementVNode("table", {
      class: _normalizeClass(["ho-grid ho-static-head ho-min-width @apply rounded-b ui-test-grid", { 'ho-grid-filters-active': filtersActive.value }])
    }, [
      _createVNode(gridHead, {
        loading: __props.loading.grid,
        columnsConfig: gridHeadConfig.value,
        selectAllMode: __props.isSelectAllMode,
        sortOptions: __props.listOptions.sort,
        onOnFiltersActive: handleFiltersActive,
        onOnFiltersChange: handleFiltersChange,
        onOnSortingChange: handleSortingChange,
        onOnSelectAllChange: handleSelectAllChange
      }, null, 8, ["loading", "columnsConfig", "selectAllMode", "sortOptions"]),
      (__props.loading.grid || visibleData.value.length > 0)
        ? (_openBlock(), _createBlock(gridBody, {
            key: 0,
            loading: __props.loading.grid,
            selectable: false,
            selectedItemIds: selectedItemIds.value,
            selectionExcludingItemIds: selectionExcludingItemIds.value,
            selectAllMode: __props.isSelectAllMode,
            data: visibleData.value,
            columnsConfig: gridHeadConfig.value,
            onOnSelectionChange: handleSelectionChange
          }, null, 8, ["loading", "selectedItemIds", "selectionExcludingItemIds", "selectAllMode", "data", "columnsConfig"]))
        : _createCommentVNode("", true),
      (!__props.loading.grid && (!visibleData.value || visibleData.value.length === 0))
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_9, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                rowspan: gridHeadConfig.value.length
              }, _toDisplayString(noDataMessage.value), 9, _hoisted_10)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}
}

})