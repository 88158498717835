import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "home-head flex"
}
const _hoisted_3 = { class: "top-bar" }
const _hoisted_4 = {
  key: 0,
  class: "user"
}
const _hoisted_5 = {
  key: 1,
  class: "user"
}
const _hoisted_6 = {
  key: 1,
  class: "home-grid"
}
const _hoisted_7 = { class: "filters-container" }
const _hoisted_8 = { class: "grid-container" }

import { computed, reactive, ref, toRaw, watch, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { endOfMonth, startOfMonth, subMonths } from "date-fns";
import { createToast } from 'mosha-vue-toastify';
import { TOAST_TYPES } from '@/constants/toastr';
import { ApiError } from "@/types/errorTypes";
import { getPaged, exportData, InvoiceLedger } from "../services/invoicesApi";
import HomeFilters from "@/components/home/filters.vue";
import ExportViesDeclaration from '@/components/home/export-vies-declaration.vue';
import ExportActions from '@/components/home/export-actions.vue';
import grid from '@/components/grid/grid.vue';
import IGridConfig from '@/components/grid/interfaces/iGridConfig'
import IListOptions from "@/interfaces/invoices/iListOptions";
import IListFilter from "@/interfaces/invoices/iListFilter";
import IFilterField from '@/interfaces/home/iFilterField';
import IInvoice from '@/interfaces/invoices/iInvoice';
import IProfile from '@/interfaces/users/iProfile';
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';
import IExportType from '@/interfaces/invoices/iExportType';
import IUser from '@/interfaces/users/iUser';
import { getSession } from '@/services/authService';
import LoadingSpinner from "@/components/loading-spinner.vue";
import router from '@/router';
import ICompany from '@/interfaces/companies/iCompany';
import IConnection from '@/interfaces/users/iConnection';

type LoadingFields = 'grid' | 'export' | 'companies' | 'import' | 'user' | 'connection';


export default /*@__PURE__*/_defineComponent({
  __name: 'VatExplorer',
  props: {
	user: {
		type: Object as () => IUser
	},
	companies: {
		type: Array as () => ICompany[]
	},
	connection: {
		type: Object as () => IConnection,
	}
},
  emits: ['onImportModalOpen', 'onFirstFilterTriggered', 'onOpenConnections'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const requiredFields = ['customerVat', 'customerCountry', 'nraDocType', 'number'];

const getInitalListOptions = (): IListOptions => {
	const now = new Date();

	const initListOptions: IListOptions = {
		pageIndex: 0,
		pageSize: 20,
		filters: [
			{
				field: 'ledger',
				value: InvoiceLedger.SALES
			},
			{
				field: 'startDate',
				value: startOfMonth(subMonths(now, 1))
			},
			{
				field: 'endDate',
				value: endOfMonth(subMonths(now, 1))
			},
			{
				field: 'includeCancelled',
				value: false
			}
		]
	};

	return initListOptions;
}

const getInitalLedgerOptions = () => {
	return [
		{
			label: localization.t('home.sales'),
			value: 'sales'
		},
		{
			label: localization.t('home.purchases'),
			value: 'purchases'
		}
	]
}

const initFilterFields: IFilterField[] = [
	{
		name: 'ledger',
		placeholderText: localization.t('home.ledger-placeholder'),
		initialValue: 'sales',
		canClear: false,
		options: getInitalLedgerOptions(),
		type: 'select'
	},
	{
		name: 'date',
		type: 'dateRange'
	},
	{
		name: 'company',
		initialValue: null,
		placeholderText: localization.t('home.company-placeholder'),
		options: [],
		canClear: false,
		type: 'select'
	},
];

const getInitialRowKeysConfig = (): {
	key: string,
	label?: string,
	class?: string,
	width?: number | string,
	minWidth?: number
}[] => {
	return [
			{
				key: 'nraDocType',
				width: 120,
				minWidth: 120,
				label: localization.t('grid.labels.nra-doc-type')
			},
			{
				key: 'number',
				width: 120,
				minWidth: 120,
				label: localization.t('grid.labels.number')
			},
			{
				key: 'invoiceDate',
				width: 140,
				minWidth: 140,
				label: localization.t('grid.labels.invoice-date')
			},
			{
				key: 'date',
				width: 140,
				minWidth: 140,
				label: localization.t('grid.labels.date')
			},
			{
				key: 'customerVat',
				width: 160,
				minWidth: 160,
				label: localization.t('grid.labels.customer-vat')
			},
			{
				key: 'customer',
				width: "10%",
				minWidth: 200,
				label: localization.t('grid.labels.customer')
			},
			{
				key: 'customerCountry',
				width: 100,
				minWidth: 100,
				label: localization.t('grid.labels.customer-country')
			},
			{
				key: 'taxBase',
				width: 100,
				minWidth: 100,
				label: localization.t('grid.labels.tax-base'),
				class: 'text-right ho-cell-number'
			},
			{
				key: 'tax',
				width: 100,
				minWidth: 100,
				label: localization.t('grid.labels.tax'),
				class: 'text-right ho-cell-number'
			},
			{
				key: 'status',
				width: 80,
				minWidth: 80,
				label: localization.t('grid.labels.status')
			}
		]
}

const validateInvoicesRequiredFields = (invoices: IInvoice[]) => {
	let hasInvalidRequiredValue = false;
	for (let i = 0; i < invoices.length; i++) {
		const invoice = invoices[i];
		const keys = Object.keys(invoice);
		keys.forEach(k => {
			if (requiredFields.includes(k) && !(invoice as {[key:string]: any})[k]) {
				hasInvalidRequiredValue = true;
			}
		});

		if (hasInvalidRequiredValue) {
			break;
		}
	}

	return hasInvalidRequiredValue;
}

const props = __props;
const emit = __emit;

const companies = ref([] as {
	label?: string,
	value: number
}[]);

const data = ref({
	data: [],
	total: 0
});
const filterFieldsConfig: Ref<IFilterField[]> = ref(initFilterFields);
const loading = ref({
	grid: false,
	export: false,
	companies: false,
	import: false,
	user: false,
	connection: false
});
const viesModalOpen = ref(false);

const firstLoadTriggered = ref(false);
const dataHasInvalidFields = ref(false);

const selections = ref({
	itemIds: [] as string[],
	excludedItemIds: [] as string[]
});

const isSelectAllMode = ref(false);

const initRowKeysConfig = ref(getInitialRowKeysConfig());

const initalListOptions = getInitalListOptions();

watch(() => localization.locale.value, () => {
	initRowKeysConfig.value = getInitialRowKeysConfig();
	
	filterFieldsConfig.value.forEach(filter => {
		if (filter.name === 'ledger') {
			filter.options = getInitalLedgerOptions();
		}
	})
});

const profileData = computed(() => {
	return toRaw({ ...(props.user || {}).profileData } as IProfile & IDeclarantExtended);
});

const exportButtonsDisabled = computed(() => {
	return loading.value['export']
		|| loading.value['grid']
		|| data.value.total === 0
		|| dataHasInvalidFields.value;
});

const exportViesButtonDisabled = computed(() => {
	let isPurchasesOn = false;
	const foundFilter = listOptions.filters?.find(f => f.field === 'ledger');
	if (foundFilter && foundFilter.value === 'purchases') {
		isPurchasesOn = true;
	}

	return isPurchasesOn;
});

const userDataUnwatch = watch(() => props.user, (newUserData, oldUserData) => {
	const sessionData = getSession();
	const hasSession = sessionData && !Number.isNaN(sessionData.session_id);

	if (!oldUserData?.userName && newUserData?.userName) {
		if (hasSession) {
			userDataUnwatch();
		}
	}
});

const companiesDataUnwatch = watch(() => props.companies, (newData, oldData) => {
	if ((!oldData || oldData.length === 0) && (newData && newData.length > 0)) {
		const companiesFilter = filterFieldsConfig.value.find(f => f.name === 'company');
		
		if (companiesFilter) {
			companiesFilter.options = (newData || []).map(c => {
				return {
					label: c.name,
					value: c.id
				}
			});

			companies.value = companiesFilter.options;

			(listOptions.filters || []).push({
				field: 'company',
				value: companiesFilter.options[0].value
			});

			companiesFilter.initialValue = companiesFilter.options.length === 1 ? companiesFilter.options[0].value : null
		}

		companiesDataUnwatch();
	}
});

const gridConfig: IGridConfig = {
	sortFields: [
		{
			field: 'invoiceDate'
		},
		{
			field: 'date'
		},
		{
			field: 'customer'
		},
		{
			field: 'number'
		}
	]
};

initalListOptions.searchFields = [
	{
		label: 'customer',
		value: 'customer'
	},
	{
		label: 'baz',
		value: 'baz'
	},
	{
		label: 'FOO',
		value: 'foo'
	}
];

const listOptions: IListOptions = reactive(initalListOptions);

const handleChangeSelection = (newSelections: string[]) => {
	if (!isSelectAllMode.value) {
		selections.value.itemIds = newSelections;
	} else {
		selections.value.excludedItemIds = newSelections;
	}
}

const handleResetSelections = () => {
	selections.value.itemIds = [];
	selections.value.excludedItemIds = [];
}

const handleChangeSelectAllMode = (newMode: boolean) => {
	isSelectAllMode.value = newMode;
}

const handleChangePage = async (page: number) => {
	listOptions.pageIndex = page;

	const resultData = await executeAsync('grid', async () => {
		return await getPaged(listOptions);
	});

	const hasInvalidFields = validateInvoicesRequiredFields(resultData.data);
	if (!dataHasInvalidFields.value && hasInvalidFields) {
		dataHasInvalidFields.value = hasInvalidFields;
	}

	data.value = resultData;
}

const handleChangeFilters = async (filters?: IListFilter[]) => {
	(filters || []).forEach(filter => {
		if (!listOptions.filters) {
			listOptions.filters = [];
		}

		const foundFilter = listOptions.filters.find(f => f.field === filter.field);
		if (foundFilter) {
			foundFilter.value = filter.value
		} else {
			const newFilter: IListFilter = {
				field: filter.field,
				value: filter.value
			}

			listOptions.filters?.push(newFilter);
		}
	});
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;
}

const handleChangeSorting = async (field: string, direction: "asc" | "desc") => {
	listOptions.sort = {
		field,
		direction
	}
	listOptions.pageIndex = 0;

	data.value = await executeAsync('grid', async () => {
		return await getPaged(listOptions);
	});
}

const handleExportData = async (
	exportType: IExportType,
	declarantData?: IProfile & IDeclarantExtended
) => {
	const exportItemIds = isSelectAllMode.value ? selections.value.excludedItemIds : selections.value.itemIds;

	await executeAsync('export', async () => {
		return exportData(exportItemIds, isSelectAllMode.value, exportType, listOptions, declarantData);
	});
}

const handleApplyFilters = async () => {
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;

	firstLoadTriggered.value = true;
	emit('onFirstFilterTriggered');
	if (dataHasInvalidFields.value !== false) {
		dataHasInvalidFields.value = false;
	}

	const resultData = await executeAsync('grid', async () => {
		return await getPaged(listOptions);
	});

	const hasInvalidFields = validateInvoicesRequiredFields(resultData.data);
	if (hasInvalidFields) {
		dataHasInvalidFields.value = hasInvalidFields;
	}

	data.value = resultData;
}

const handleViesModalOpen = () => {
	viesModalOpen.value = true;
}

const handleViesModalClose = (declarantData?: IProfile & IDeclarantExtended) => {
	if (declarantData) {
		const exportType: IExportType = {
			fileType: 'TXT',
			includeVies: true
		}
		handleExportData(exportType, declarantData);
	}
	viesModalOpen.value = !viesModalOpen.value;
}

const handleImportModalOpen = () => {
	emit('onImportModalOpen');
}

// TODO extract in HoC:
const executeAsync = async (loadingField: LoadingFields, exec: any, showToast = true) => {
	loading.value[loadingField] = true;

	try {
		const result = await exec();
		loading.value[loadingField] = false;
		return result;
	} catch (error) {
		const err = error as ApiError;
		if (err.statusCode === 401) {
			router.push('/login');
		} else {
			showToast && createToast(err.message, TOAST_TYPES.ERROR);

			if (process.env.NODE_ENV !== 'production' && showToast && err.innerMessage) {
				createToast(err.innerMessage, TOAST_TYPES.ERROR);
			}

			throw error;
		}
	} finally {
		loading.value[loadingField] = false;
	}
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["home-wrapper", { initial: !firstLoadTriggered.value }])
  }, [
    (loading.value.companies)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!firstLoadTriggered.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (__props.connection)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_unref(localization).t('home.filter-message')) + " ", 1),
                    _createElementVNode("div", {
                      class: "btn",
                      onClick: handleImportModalOpen
                    }, _toDisplayString(_unref(localization).t('home.import-convert')), 1),
                    _createTextVNode(" " + _toDisplayString(_unref(localization).t('home.vat-file')), 1)
                  ]))
                : _createCommentVNode("", true),
              (!__props.connection)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_unref(localization).t('app.no-connection')) + ". ", 1),
                    _createElementVNode("div", {
                      class: "btn",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('onOpenConnections')))
                    }, _toDisplayString(_unref(localization).t('ho-modal-connect.select-connection')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (__props.connection && __props.connection.active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(HomeFilters, {
                loading: loading.value,
                filterFields: filterFieldsConfig.value,
                filters: listOptions.filters,
                onOnFiltersChange: handleChangeFilters,
                onOnApplyFilters: handleApplyFilters
              }, null, 8, ["loading", "filterFields", "filters"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              (firstLoadTriggered.value)
                ? (_openBlock(), _createBlock(grid, {
                    key: 0,
                    loading: loading.value,
                    config: gridConfig,
                    data: data.value.data,
                    total: data.value.total,
                    listOptions: listOptions,
                    profileData: profileData.value,
                    hasDataErrors: dataHasInvalidFields.value,
                    isSelectAllMode: isSelectAllMode.value,
                    selections: selections.value,
                    rowKeysConfig: initRowKeysConfig.value,
                    onOnChangePage: handleChangePage,
                    onOnChangeFilters: handleChangeFilters,
                    onOnChangeSorting: handleChangeSorting,
                    onOnChangeSelection: handleChangeSelection,
                    onOnChangeSelectAllMode: handleChangeSelectAllMode,
                    onOnResetSelection: handleResetSelections
                  }, {
                    topBarPlaceholder: _withCtx(() => [
                      _createVNode(ExportActions, {
                        loading: loading.value.export,
                        hasDataErrors: dataHasInvalidFields.value,
                        exportButtonsDisabled: exportButtonsDisabled.value,
                        exportViesButtonDisabled: exportViesButtonDisabled.value,
                        onOnExportClick: handleExportData,
                        onOnExportViesClick: handleViesModalOpen
                      }, null, 8, ["loading", "hasDataErrors", "exportButtonsDisabled", "exportViesButtonDisabled"])
                    ]),
                    _: 1
                  }, 8, ["loading", "data", "total", "listOptions", "profileData", "hasDataErrors", "isSelectAllMode", "selections", "rowKeysConfig"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (viesModalOpen.value)
        ? (_openBlock(), _createBlock(ExportViesDeclaration, {
            key: 2,
            profileData: profileData.value,
            onOnExportModalClose: handleViesModalClose
          }, null, 8, ["profileData"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})